import ClosIcon from '../../assets/images/CloseIcon.svg';


import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { Swipers } from '../Swiper/Swiper';
import { useEffect, useState } from 'react';
import LeaguesHttp from '../../http/LeaguesHttp/LeaguesHttp';
import { useSelector } from 'react-redux';
import WebApp from '@twa-dev/sdk';

export default function ModalLevels({ open, setOpen, id, user_points }) {

  const [data,setData] = useState([])

  const user = useSelector(state => state?.user?.userData?.user);
  const league = useSelector(state => state?.user?.userData?.league);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    LeaguesHttp.getLeaguesList(id,WebApp?.initData)
      .then(e => setData(e?.data))
      .catch(error => console.log(error, 'error'));
  }, []);

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full h-full items-end justify-center text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform h-[60%]  rounded-t-[44px] overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 bg-[#0F0F0F] "
            style={{ backgroundColor: '#0F0F0F' }}>
            <div className="h-full">
              <div className="mt-3 text-center sm:mt-5">
                <div className="flex justify-end pr-2">
                  <button onClick={onClose}>
                    <img src={ClosIcon} alt="CLoseIcon" />
                  </button>
                </div>
              </div>
              <Swipers data={data} user_points={user_points} user={league}/>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
