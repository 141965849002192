import { $authHost } from "..";


export default class ReferralsHttp {

  static getReferralsList(id,initData) {
    return $authHost.get(`/referrals/${id}?${initData}`);
  };
  static claimReferralsList(id,referral_id, initData) {
    return $authHost.post(`/referrals/${id}/claim/${referral_id}?${initData}`);
  };


}