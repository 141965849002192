import { useEffect } from 'react';
import CoinIcon from '../../assets/images/CoinIcon.png';
import InviteButton from '../../components/Buttons/InviteButton';
import InputCopyLink from '../../components/Inputs/InputCopyLink';
import RefferalsItems from '../../components/Referrals/RefferalsItems/RefferalsItems';
import { BackButton } from '@twa-dev/sdk/react';
import ReferralsHttp from '../../http/ReferralsHttp/ReferralsHttp';
import WebApp from '@twa-dev/sdk';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSlice } from '../../redux/user/user';
import NoReferrals from '../../components/Referrals/NoReferrals/NoReferrals';
import { formatNumber } from '../../utils';

export default function Referrals() {

  const dispatch = useDispatch();

  // const [referralsData, setReferralsData] = useState([]);

  const invit_link_copy = process.env.REACT_APP_TG_BOT_COPY_LINK;

  const id = WebApp?.initDataUnsafe?.user?.id;

  const onClickClaimReferral = (id, task_id) => {
    ReferralsHttp.claimReferralsList(
      id,
      task_id,
      WebApp?.initData,
    );
  };
  const referralsData = useSelector(state => state?.referrals?.referralsData);


  useEffect(() => {
    dispatch(userDataSlice(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className='h-[67%]'>
      <BackButton />
      <div className='flex flex-col items-center justify-center pt-0 gap-y-2'>
        <p
          className='text-3xl text-white font-semibold font-switzer'>{formatNumber(referralsData?.referral_count)} Referrals</p>
        <div className='flex gap-x-2 items-center'>
          <img src={CoinIcon} alt='CoinIcon' className='w-[43px] h-[43px]' />
          <p
            className='font-switzer text-[#FFEB3B] text-3xl font-semibold'>+{formatNumber(referralsData.total_profit)}</p>
        </div>
      </div>
      <div className='px-4 h-full'>
        <div className='h-full'>
          <InputCopyLink
            value={`${invit_link_copy}${
              WebApp?.initDataUnsafe?.user?.id
            }`}
          />
          {!!referralsData?.referrals?.length ?
            <RefferalsItems data={referralsData?.referrals} onClickClaimReferral={onClickClaimReferral} id={id} /> :
            <NoReferrals />}
        </div>
      </div>
      <div className='mt-5 px-4 fixed bottom-[100px] w-full'>
        <InviteButton />
      </div>
    </div>
  );
}
