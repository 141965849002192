import CoinIcon from '../../assets/images/CoinIcon.png';
import ArrowRight from '../../assets/images/ArrowRight.svg';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ShowSpecial from './components/ShowSpecial/ShowSpecial';
import ShowLeagues from './components/ShowLeagues/ShowLeagues';
import TaskTub from './components/TaskTub/TaskTub';
import ModalLevels from '../../components/Modal/ModalLevels';
import { BackButton } from '@twa-dev/sdk/react';
import TaskHttp from '../../http/TaskHttp/TaskHttp';
import WebApp from '@twa-dev/sdk';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSlice } from '../../redux/user/user';
import ShowReferrals from './components/ShowReferrals/ShowReferrals';
import RoundedIconLevels from '../../components/RoundedIconLevels/RoundedIconLevels';
import { formatNumber, sortDataByStatusAndId } from '../../utils';

export default function Task() {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('Special');
  const [dataTask, setDataTask] = useState([]);
  const [dataLeagues, setDataLeagues] = useState([]);
  const [dataReferrals, setDataReferrals] = useState([]);
  const [open, setOpen] = useState(false);
  const [upd, setUpd] = useState(false);

  const id = WebApp?.initDataUnsafe?.user?.id;

  const onOpenModal = useCallback(() => {
    setOpen(true);
  }, []);

  const onClickTab = useCallback(title => {
    setActiveTab(title);
  }, []);

  const user = useSelector(state => state?.user?.userData?.user);
  const league = useSelector(state => state?.user?.userData?.league);

  const SpecialData = useMemo(() => dataTask, [dataTask]);
  const LeaguesData = useMemo(() => dataLeagues, [dataLeagues]);
  const ReferralsData = useMemo(() => dataReferrals, [dataReferrals]);

  useEffect(() => {
    dispatch(userDataSlice(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, upd]);

  const ShowContent = useCallback(() => {
    return activeTab === 'Special' ? (
      <ShowSpecial data={sortDataByStatusAndId(SpecialData)} tg_id={id} />
    ) : activeTab === 'Leagues' ? (
      <ShowLeagues data={LeaguesData} user_points={user?.points_count} telegram_id={id} setUpd={setUpd} />
    ) : (
      <ShowReferrals referral={true} data={ReferralsData} telegram_id={id} setUpd={setUpd} />
    );
  }, [activeTab, LeaguesData, SpecialData, ReferralsData]);

  useEffect(() => {
    activeTab === 'Special'
      ? TaskHttp.getTasksList(
          id,
          WebApp?.initData,
        )
          .then(e => setDataTask(e?.data))
          .catch(error => console.log(error, 'error'))
      : activeTab === 'Leagues'
      ? TaskHttp.getLeaguesList(
          id,
          WebApp?.initData,
        )
          .then(e => setDataLeagues(e?.data))
          .catch(error => console.log(error, 'error'))
      : activeTab === 'RefTasks' &&
        TaskHttp.getReferralsList(
          id,
          WebApp?.initData,
        )
          .then(e => setDataReferrals(e?.data))
          .catch(error => console.log(error, 'error'));
  }, [id, activeTab, upd]);

  return (
    <div className="h-[87%]">
      <BackButton />
      <div className="flex flex-col items-center justify-center pt-0 gap-y-2">
        <p className="text-2xl font-semibold font-switzer text-white">Earn more coins</p>
        <p className="text-sm font-normal font-switzer text-[#979797]">
        Please complete the milestones below to earn rewards
        </p>
        <div className="flex flex-col gap-x-2 items-center justify-center">
          <img src={CoinIcon} alt="CoinIcon" className="w-[43px] h-[43px]" />
          <p className="font-switzer text-[#FFEB3B] text-3xl font-semibold">{formatNumber(user?.points_count)}</p>
          <div className="flex gap-x-2 mt-2 items-center coursor-pointer" onClick={onOpenModal}>
            <RoundedIconLevels level_id={league?.league_id} />
            <p className="font-switzer text-base font-normal coursor-pointer text-white">{league?.title}</p>
            <button className="h-4 w-4">
              <img src={ArrowRight} alt="ArrowRight" />
            </button>
          </div>
        </div>
      </div>
      <TaskTub activeTab={activeTab} onClickTab={onClickTab} />
      <ShowContent />
      <ModalLevels open={open} setOpen={setOpen} id={id} user_points={user?.points_count} />
    </div>
  );
}
