import { createSlice } from '@reduxjs/toolkit';
import ReferralsHttp from '../../http/ReferralsHttp/ReferralsHttp';
import WebApp from '@twa-dev/sdk';

const referralsSlice = createSlice({
  name: 'referralsSlice',
  initialState: {
    referralsData: '',
  },
  reducers: {
    REFERRALS_DATA(state, action) {
      state.referralsData = action.payload;
    },
  },
});

export const referralsDataSlice = id => async dispatch => {
  try {
    const data = await ReferralsHttp.getReferralsList(id,WebApp?.initData);
    dispatch(REFERRALS_DATA(data?.data));
  } catch (error) {
    console.log(error, 'error');
  }
};

export const { REFERRALS_DATA } = referralsSlice.actions;
export default referralsSlice.reducer;
