import CoinIcon from '../../assets/images/CoinIcon.png';
import { BackButton } from '@twa-dev/sdk/react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatNumber, sortDataByStatusAndId } from '../../utils';
import TaskHttp from '../../http/TaskHttp/TaskHttp';
import WebApp from '@twa-dev/sdk';
import { useEffect, useState } from 'react';
import TaskComponent from './TaskComponent/TaskComponent';

export default function OurSocials() {
  const [data, setData] = useState([]);
  const [upd, setUpd] = useState(false);
  const justNavigate = useNavigate();

  const tg_id = WebApp?.initDataUnsafe?.user?.id;

  const { id } = useParams();

  const onClickClaim = (tg_id, id) => {
    TaskHttp.claimMission(tg_id, id, WebApp?.initData);
  };

  useEffect(() => {
    // seconds === 0 || seconds === 10 &&
    TaskHttp.getTasksList(tg_id, WebApp?.initData)
      .then(e => {
        const dataTask = e?.data.find(item => item?.id == id);
        setData(dataTask);
      })
      .catch(error => console.log(error, 'error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, upd]);

  return (
    <div className='h-[87%]'>
      <BackButton />
      <div className='flex flex-col items-center justify-center pt-0 gap-y-2 max-w-[70%] mx-auto'>
        <p className='text-2xl font-medium font-switzer text-white '>{data?.title}</p>
        <p className='text-sm font-normal font-switzer text-[#979797] text-center'>{data?.description}</p>
        <div className='flex flex-col gap-x-2 items-center justify-center mt-2'>
          <img src={CoinIcon} alt='CoinIcon' className='w-[43px] h-[43px]' />
          <p className='font-switzer text-[#FFEB3B] text-3xl font-semibold'>{formatNumber(data?.bonus_points)}</p>
        </div>
      </div>
      <div className='px-4 flex flex-col gap-y-2 mt-8 max-h-[46%] overflow-y-auto'>
        {sortDataByStatusAndId(data?.tasks)?.map(({ title, id, bonus_points, icon, link, status }) => (
          <TaskComponent title={title} id={id} bonus_points={bonus_points} icon={icon} link={link} status={status}
                         setUpd={setUpd} tg_id={tg_id} />
        ))}
      </div>
      <div className='mt-5 px-4 fixed bottom-[90px] w-full'>
        <button
          className={
            data?.status !== 'unclaimed'
              ? 'bg-[#3D3D40] text-[#979797] font-medium font-base rounded-[11px] w-full h-[56px]'
              : 'bg-gradient-to-b from-[#FFB000] to-[#FF4000] font-medium font-base rounded-[11px] w-full h-[56px] text-white'
          }
          disabled={data?.status !== 'unclaimed'}
          onClick={() => onClickClaim(tg_id, id)}>
          Finish mission
        </button>
      </div>
    </div>
  );
}
