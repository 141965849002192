import { $authHost } from '..';


export default class BoostsHttp {

  static getBoosts(id, initData) {
    return $authHost.get(`/boosts/${id}?${initData}`);
  };

  static getDailyBoosts(id, task_id, initData) {
    return $authHost.post(`/boosts/${id}/daily/${task_id}?${initData}`);
  };

  static getBuyBoosts(id, task_id, initData) {
    return $authHost.post(`/boosts/${id}/buy/${task_id}?${initData}`);
  };

}