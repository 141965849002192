import { $authHost } from "..";


export default class UserHttp {

  static getUser(id, initData) {
    return $authHost.get(`/users/${id}?${initData}`);
  };
  static createUser(data, initData) {
    return $authHost.post(`/users?${initData}`, data);
  };
}