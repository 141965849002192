import { $authHost } from "..";


export default class TaskHttp {

  static getTasksList(id,initData) {
    return $authHost.get(`/missions/${id}?${initData}`);
  };
  static getLeaguesList(id,initData) {
    return $authHost.get(`/missions/${id}/leagues?${initData}`);
  };
  static getReferralsList(id,initData) {
    return $authHost.get(`/missions/${id}/referrals?${initData}`);
  };
  static claimReferral(id,task_id,initData) {
    return $authHost.patch(`/missions/${id}/referrals/claim/${task_id}?${initData}`);
  };
  static claimMission(id,task_id,initData) {
    return $authHost.patch(`/missions/${id}/claim/${task_id}?${initData}`);
  };
  static claimLeague(id,task_id,initData) {
    return $authHost.patch(`/missions/${id}/leagues/claim/${task_id}?${initData}`);
  };
  static startTask(id,task_id,initData) {
    return $authHost.patch(`/tasks/${id}/start/${task_id}?${initData}`);
  };
  static claimTask(id,task_id,initData) {
    return $authHost.patch(`/tasks/${id}/claim/${task_id}?${initData}`);
  };
}