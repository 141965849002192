import ClosIcon from '../../assets/images/CloseIcon.svg';
import CoinIcon from '../../assets/images/CoinIcon.png';
import MultiIcon from '../../assets/images/MultiIcon.png';
import RefillIcon from '../../assets/images/RefillIcon.png';

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import WebApp from '@twa-dev/sdk';
import BoostsHttp from '../../http/BoostsHttp/BoostsHttp';
import { formatString } from '../../utils';
import { useNavigate } from 'react-router-dom';

export default function ModalBoostsDaily({ open, setOpen, data, id, setUpd }) {
  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const height = WebApp.viewportHeight;

  const onClickGetIt = (id, task_id) => {
    BoostsHttp.getDailyBoosts(
      id,
      task_id,
      WebApp?.initData,
    ).then(e => setUpd(prev => !prev));
    onClose();
    navigate('/');
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full h-full items-end justify-center text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform h-[60%] rounded-t-[44px] overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 bg-[#0F0F0F] "
            style={{ backgroundColor: '#0F0F0F' }}>
            <div className="h-full">
              <div className="mt-3 text-center sm:mt-5">
                <div className="flex justify-end pr-2">
                  <button onClick={onClose}>
                    <img src={ClosIcon} alt="CLoseIcon" />
                  </button>
                </div>
              </div>
              <div
                className={`text-white mx-auto px-4 w-full flex flex-col items-center justify-center ${
                  height > 667 && 'mt-[10%]'
                } `}>
                <div className="">
                  {data?.type === 'multiplier' ? (
                    <img src={MultiIcon} alt="MultiIcon" />
                  ) : (
                    <img src={RefillIcon} alt="RefillIcon" />
                  )}
                </div>
                <div className="flex flex-col gap-y-2 items-center mt-[6%]">
                  <p className="text-2xl font-medium text-white">{formatString(data?.title ?? '')}</p>
                  <p className="text-sm font-normal font-switzer text-[#979797] text-center">
                    {formatString(data?.description ?? '')}
                  </p>
                </div>
                <div className="flex items-center gap-x-2 mt-[4%]">
                  <img src={CoinIcon} alt="CoinIcon" className="w-6 h-6" />
                  <p className="text-2xl font-medium text-[#FFEB3B]">Free</p>
                </div>
                <div className="w-full mt-[6%] mb-[2%]">
                  <button
                    className={
                      data?.used_count >= 3
                        ? 'w-full bg-[#3D3D40] text-[#979797] h-[56px] rounded-[11px] flex items-center justify-center'
                        : 'w-full bg-gradient-to-b from-[#FFB000] to-[#FF4000] h-[56px] rounded-[11px] flex items-center justify-center'
                    }
                    onClick={() => onClickGetIt(id, data?.task_id)}
                    disabled={data?.used_count >= 3}>
                    <div className="flex gap-x-2">
                      <p className="text-white text-base font-medium font-switzer">Get it!</p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
